<template>
     <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-tape-measure"
          title="Measurement Requests"
          :value="statsData.design.measurementRequest"
         />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-check"
          title="Approved Measurement"
          :value="statsData.design.approveMeasurement"
        
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-pencil-ruler"
          title="Sample Design Request"
          :value="statsData.design.sampleDesignRequest"
         />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-home-variant-outline"
          title="Render Views Request"
          :value="statsData.design.renderViewRequest"
         />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-home-variant-outline"
          title="Detailed Design Request"
          :value="statsData.design.detailedDesignRequest"
         />
      </v-col>

      


     
    </v-row>
</template>


<script>
export default {

    data(){
        return{
            statsData: {}
        }
    },

    methods:{
        getDashboard(){
            this.$DashboardApi.getDashboard().then(data=>{
                console.log(data.data.data);
                this.statsData = data.data.data;
            }).catch(error=>{
                console.log(error);
            })
        }
    },

    mounted(){
        this.getDashboard();
    }
    
}
</script>


<style scoped>

</style>