<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
  <StatsWidgetComponent/>
    <!-- <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Available Tasks"
          value="8"
         />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-poll"
          title="Task Completed"
          value="11"
        
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Average Rating"
          value="4.5"
         />
      </v-col>

      


     
    </v-row> -->

     <!-- <v-data-table
    :headers="headers"
    :items="tasks"
    
    hide-default-footer
    class="elevation-1"
    @click:row="handleItemClick"
  ></v-data-table> -->
  </v-container>
</template>

<script>
import StatsWidgetComponent from "./components/core/StatsWidgetComponent.vue";
  export default {
    name: 'DashboardDashboard',

    components:{
      StatsWidgetComponent
    },

    data () {
      return {
           headers: [
             {
               text:'Conversation Id',
               value: 'conversationId'
             },
          {
            text: 'Message Activity Id',
            align: 'start',
            value: 'messageActivityId',
          },
          { text: 'Question', value: 'question' },
        
        
        ],
        tasks: [
         
        ],
      }
    },

    methods: {
      handleItemClick (ev) {
        this.$router.push({path:'/message/'+ev.conversationId, query:{messageActivity: ev.messageActivityId}})
      },
      getTasks(){
        this.$MessageApi.getTasks().then(data=>{
          console.log(data.data);
          this.tasks = data.data.data;
        }).catch(error=>{
          console.log(error);
        })
      }
    },

    mounted(){
      // this.getTasks();
      this.$socket.client.close();
    }
  }
</script>
